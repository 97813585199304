$(document).ready(function() {
    /*frappe.call({
        method: "guerrieromarketing.userscustomization.rolebyuser",
        callback: function(r) {
            if (r.message.includes("Users")) {
                //$('.form-footer').hide();
                // Hide search bar
                //$('.search-bar').hide();
                // Hide footer and comments
                

                // Hide sidebar
                //$('.layout-main .layout-side-section').hide();

                // Hide main page head
                //$('#page-Workspaces .page-actions').hide();

                // Change list height
                // $('.layout-main-section').css('height', '37rem');
                // $('.layout-main-section').css('overflow-y', 'auto');
            }
        }
    });*/
    alert("test");
    frappe.call({
        method: "guerrieromarketing.userscustomization.get_footer",
        callback: function(r) {
            $('footer').html('<hr>'+r.message);
            $('footer').css("margin-top", "3rem");
        }

    });
});


